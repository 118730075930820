$(function() {
    //
    // Global
    //
    let controller = new ScrollMagic.Controller();
    const $root = $('html, body');
    let resized;
    let _windowWidth = $(window).outerWidth();

    function resizeWidthOnly() {
        let currentWindowWidth = $(window).outerWidth();
        if (_windowWidth !== currentWindowWidth) {
            _windowWidth = currentWindowWidth;
            return _windowWidth;
        } else {
            return false;
        }
    }

    function resizedw() {
        if (resizeWidthOnly() !== false) {
            setCrewScenes();
            hideAllNavElements();
        }
    }

    window.onresize = function () {
        clearTimeout(resized);
        resized = setTimeout(resizedw, 100);
    }

    //
    // Header
    //
    new ScrollMagic.Scene({
        triggerElement: ".mmsi-hero",
        triggerHook: 0,
        offset: 1
    })
        .addTo(controller)
        .on("enter", function () {
            $(".mmsi-header").addClass("mmsi-header-sticky");
        })
        .on("leave", function () {
            $(".mmsi-header").removeClass("mmsi-header-sticky");
        });


    //Parallax Copy
    function parallax() {
        [].slice.apply(document.querySelectorAll(".parallax-copy")).forEach(function (el) {
            let opts = {triggerElement: el, triggerHook: "onEnter", duration: "125%"},
                scene = new ScrollMagic.Scene(opts).setTween(el, {x: "-25%", ease: Linear.easeNone}).addTo(controller);
        });

        //Parallax rotated items
        [].slice.apply(document.querySelectorAll(".parallax-rotated")).forEach(function (el) {
            let opts = {triggerElement: el, triggerHook: "onEnter", duration: "150%"},
                scene = new ScrollMagic.Scene(opts).setTween(el, {y: "-50%", ease: Linear.easeNone}).addTo(controller);
        });

        //Parallax background
        [].slice.apply(document.querySelectorAll(".parallax-parent")).forEach(function (el) {
            let opts = {triggerElement: el, triggerHook: "onEnter", duration: "175%"},
                scene = new ScrollMagic.Scene(opts).setTween($(el).find(".parallax-parent-img"), {
                    y: "80%",
                    ease: Linear.easeNone
                }).addTo(controller);

            $(el).fadeIn();
        });

        //fade up
        [].slice.apply(document.querySelectorAll(".fade-up")).forEach(function (el) {
            let opts = {triggerElement: el, triggerHook: 1, offset: 20},
                scene = new ScrollMagic.Scene(opts).addTo(controller);
            scene.on("start end", function (event) {
                el.classList.add("in-view");
            });
        });

        [].slice.apply(document.querySelectorAll(".fade-up-children")).forEach(function (el) {
            let opts = {triggerElement: el, triggerHook: 1, offset: 20},
                scene = new ScrollMagic.Scene(opts).addTo(controller);
            scene.on("start end", function (event) {
                el.classList.add("in-view");
            });
        });
    }

    parallax();

    // Smooth scrolling for anchor link
    function smoothScroll() {
        $(document).on("click", 'a[href^="#"]', function () {
            if ($.attr(this, 'href').length > 1 && !$.attr(this, 'rel')) {
                $root.animate({
                    scrollTop: $($.attr(this, 'href')).offset().top - 64
                }, 300);

                hideNavMenu();
            }

            return false;
        });
    }

    smoothScroll();

    //For fixed nav background
    $(window).scroll(function () {
        setStickyHeader();
    });

    setStickyHeader();

    function setStickyHeader() {
        if ($(document).scrollTop() > 0) {
            $(".vip-header").addClass("active");
        } else {
            $(".vip-header").removeClass("active");
        }
    }

    //Hamburger menu click event
    $("#js-hamburger").click(function (e) {
        e.preventDefault();
        $("body").addClass("no-scroll");
        $(this).toggleClass("active");
        $(".mmsi-header").toggleClass("active");

        if (!$(".mmsi-header").hasClass("active")) {
            $("body").removeClass("no-scroll");
        }
    });

    //Hide Menu
    function hideNavMenu() {
        $(".mmsi-header-hamburger, .mmsi-header").removeClass("active");
        $("body").removeClass("no-scroll");
    }

    function hideAllNavElements() {
        $(".mmsi-header").removeClass("active");
        $("mmsi-header__col-hamburger").removeClass("mmsi-header__col-hamburger-open").addClass("mmsi-header__col-hamburger-closed");
        $("body").removeClass("no-scroll");
    }

    function navTriggers() {
        let navTriggerArray = [];

        $(".nav-trigger").each(function (i, obj) {
            navTriggerArray[i] = new ScrollMagic.Scene({
                triggerElement: obj,
                triggerHook: 0,
                duration: $(obj).outerHeight(),
                offset: -$('.mmsi-header').outerHeight()
            })
                .addTo(controller);

            navTriggerArray[i]
                .on("enter", function (event) {
                    $(".mmsi-header-nav .link-inherit").removeClass("active");
                    $(".js-nav-" + i).addClass("active");
                })
                .on("leave", function (event) {
                    $(".mmsi-header-nav .link-inherit").removeClass("active");
                });
        });
    }

    navTriggers();

    //CREW
    let crewScenesTrigger = false;
    let crewScenes = [];

    function setCrewScenes() {
        if (crewScenesTrigger) {
            crewScenesTrigger = crewScenesTrigger.destroy(true);
        }

        crewScenesTrigger = new ScrollMagic.Scene({
            triggerElement: ".mmsi-crew",
            triggerHook: 0
        })
            .addTo(controller)
            .on("enter", function () {
                $(".crew-sticky-col").each(function (i, obj) {
                    if (crewScenes[i]) {
                        crewScenes[i] = crewScenes[i].destroy(true);
                    }

                    if (_windowWidth > 767) {
                        crewScenes[i] = new ScrollMagic.Scene({
                            triggerElement: obj,
                            duration: ($(".crew-sticky-row").outerHeight() - $(".crew-col").outerHeight()) - $(obj).position().top,
                            offset: 64
                        })
                            .setPin($(obj).find(".crew-sticky")[0])
                            .addTo(controller);
                    }
                });
            })
    }

    setCrewScenes();

    // function playerEvents() {
    //     let player = new Vimeo.Player(document.getElementById("mmsi-video"));
    //
    //     player.on("play", function() {
	// 			pushGAevent('CREWgohealthLP','Video_Play','VimeoID_788341359');
    //     });
    //
    //     player.on('ended', function(data) {
	// 			pushGAevent('CREWgohealthLP','Video_Complete','VimeoID_788341359');
    //     });
    // }
    //
    // playerEvents();
});

let player;
function onYouTubeIframeAPIReady() {
    player = new YT.Player('mmsi-video', {
        videoId: '7XoDatLb_JY',
        playerVars: {
            'playsinline': 1,
            'rel': 0
        },
        events: {
            'onStateChange': onPlayerStateChange
        }
    });
}

function onPlayerStateChange(event) {
    if (event.data === 1) {
        pushGAevent('CREWgohealthLP', 'Video_Play', 'YouTubeID_7XoDatLb_JY');
    } else if (event.data === 0) {
        pushGAevent('CREWgohealthLP', 'Video_Complete', 'YouTubeID_7XoDatLb_JY');
    }
}

// ===================================================================
// Global Function to push GA events to GTM dataLayer
// ===================================================================
function pushGAevent(category,action,label){
	if (typeof window.dataLayer !== 'undefined'){
		window.dataLayer.push({
			'event': 'Google Analytics - Event',
			'GAEvent_category': 'CREWgohealthLP',
			'GAEvent_action': action,
			'GAEvent_label': label
		});
	}
}
